import BoxWrapper from "./BoxWrapper";
import {useEffect, useState} from "react";

export default function BoxPlanner({expanded, onExpandToggle}) {
    const [content, setContent] = useState(null);

    useEffect( () => {
        (async ()=> {
            const userProfileId = document.querySelector('#loggedInUser').dataset.profilId;
            const resp = await fetch(`/teams/personsCalendar?person=${userProfileId}`);
            if (resp.ok) {
                const html = await resp.text();
                setContent({__html: html});
                setTimeout(() => {
                    if (typeof window.initTeamCalendar === 'function') window.initTeamCalendar();
                },100 );
            }
            else {
                setContent({__html: resp.statusText});
            }
        })();
        return () => {}
    }, []);


    return <BoxWrapper
        title="Mój Planer"
        icon="calendar"
        cta="Przejdź do Planera"
        action="/planer"
        className={"planner"+(expanded ? " expanded":'')}
        children=""
        collapseOnMobile={true}
        onExpandToggle={onExpandToggle}
        loaderCondition={ () => content === null}
    >
        <div dangerouslySetInnerHTML={content}></div>
    </BoxWrapper>
}
